import {
  ProfileFieldsDelivery as ProfileFieldsDeliveryClass,
  ProfileFieldsDeliveryCashbackH2, ProfileFieldsDeliveryH2,
} from './cartFields.module.css';
import CartFieldsDelivery from './CartFieldsDelivery/cartFieldsDelivery';
import CartFieldsSelfService from './CartFieldsSelfService/cartFieldsSelfService';
import H2 from '../../../Blocks/H2/H2';
import { AuthContext, Context } from '../../../../Utils/Store/store';
import sendPromocode from '../../../../Utils/api/sendPromocode';
import { isArrayEmpty } from '../../../../Utils/utils';
import checkorder from '../../../../Utils/api/checkorder';
import CashbackSlider from '../../../Blocks/CashbackSlider/cashbackSlider';
import getMaxCashbackToUse from '../../../../Utils/orderUtils';
import LinkTo from '../../../Blocks/LinkTo/linkTo';
import Backdrop from '@material-ui/core/Backdrop';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { captureException } from '@sentry/gatsby';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

interface CartFieldsProps {
  nodate?: boolean;
  setDeliveryCost: () => void;
  setSelectedUserCashback: () => void;
  formik: {}
  disableControlls: boolean;
  userCashback: number;
  totalPrice: number;
}

const useStyles = makeStyles((theme) => ({
  selected: {
    '&&': {
      backgroundColor: '#84B420',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#84B420',
      },
    },
  },
  backdropRoot: {
    zIndex: 10000,
  },
  root: {
    fontWeight: 400,
    color: '#767676',
    border: '1px solid #eaeaea',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}));

const CartFields = ({
  setDeliveryCost, formik, setSelfService,
  isSelfService, infoFromApi, promocodeNameUsed,
  setDiscount, disableControlls, userCashback,
  totalPrice, setSelectedUserCashback,
}: CartFieldsProps) => {
  const [userIsNotWriting, setUserIsNotWriting] = useState(true);
  const { authState } = useContext(AuthContext);
  const { state } = useContext(Context);

  const [splashScreen, setSplashScreen] = useState(false);

  const [openPromotionAlert, setOpenPromotionAlert] = useState(false);
  const [openStreetAlert, setOpenStreetAlert] = useState(false);
  const [openCheckorderAlert, setOpenCheckorderAlert] = useState(false);
  const [checkorderAlertText, setCheckorderAlertText] = useState(false);

  const classes = useStyles();

  const onCloseSnackbar = () => {
    setOpenPromotionAlert(false);
    setOpenCheckorderAlert(false);
    setOpenStreetAlert(false);
    setCheckorderAlertText(null);
  };

  const handleGetOrderTypeChange = async (e, value) => {
    const discountArr = [];
    const isSelfServiceSelected = value === 'Самовывоз';

    if (isSelfServiceSelected) {
      setSelfService(true);
    } else {
      setSelfService(false);
    }

    try {
      await sendPromocode(infoFromApi.Phone,
        state.cart, state.totalPrice, null, promocodeNameUsed, isSelfServiceSelected)
        .then((res) => {
          res.data.forEach((val) => {
            if (!isArrayEmpty(val.discounts)) {
              const discSum = val.discounts.reduce((a, v) => a + v.discountSum, 0);
              discountArr.push({ name: val.promotionName, discountSum: discSum });
            }
          });
          setDiscount(discountArr);
        });
    } catch (error) {
      captureException(error);
    }
  };

  const checkUserOrder = () => checkorder(formik, authState.userId, state.cart, state.totalPrice)
    .then((res) => {
      if (res.data.data.deliveryServiceProductInfo
        && res.data.data.deliveryServiceProductInfo.productSum) {
        setSplashScreen(false);
        setDeliveryCost(res.data.data.deliveryServiceProductInfo.productSum);
      } else {
        setSplashScreen(false);
        setDeliveryCost(0);
      }
    })
    .catch((err) => {
      setSplashScreen(false);
      captureException(err);
      // setOpenCheckorderAlert(true);
      // if (err.response.data && err.response.data.message)
      //   setCheckorderAlertText(err.response.data.message.problem);
    });

  useEffect(() => {
    setUserIsNotWriting(false);
    const timeoutId = setTimeout(() => setUserIsNotWriting(true), 2000);
    return () => clearTimeout(timeoutId);
  }, [formik.values]);

  useEffect(() => {
    (async () => {
      const isInputsFilled = formik.values.houseNumber
        && formik.values.street
        && formik.values.name
        && (!formik.errors.phone && formik.values.phone);

      if (isInputsFilled && userIsNotWriting) {
        setSplashScreen(true);
        await checkUserOrder();
      }
    })();
  }, [formik.values.houseNumber, formik.values.name,
  formik.values.street, formik.values.phone, userIsNotWriting]);

  return (
    <div className={`${ProfileFieldsDeliveryClass}`}>
      <H2 className={ProfileFieldsDeliveryH2}>Способ получения</H2>

      <ToggleButtonGroup
        value={isSelfService ? 'Самовывоз' : 'Доставка'}
        exclusive
        onChange={handleGetOrderTypeChange}
        aria-label="способы получения"
      >
        <ToggleButton disabled={disableControlls} classes={{ root: classes.root, selected: classes.selected }} value="Доставка" aria-label="Доставка">
          Доставка
        </ToggleButton>
        <ToggleButton disabled={disableControlls} classes={{ root: classes.root, selected: classes.selected }} value="Самовывоз" aria-label="Самовывоз">
          Самовывоз на Кожанова 12
        </ToggleButton>
      </ToggleButtonGroup>

      {!isSelfService && <CartFieldsDelivery setOpenStreetAlert={setOpenStreetAlert} disableControlls={disableControlls} formik={formik} />}
      {isSelfService && <CartFieldsSelfService disableControlls={disableControlls} formik={formik} />}

      {userCashback > 0 && (
        <>
          <H2 className={ProfileFieldsDeliveryCashbackH2}>Снять кешбэк</H2>
          <p style={{ marginBottom: '30px', color: '#666', marginTop: '12px' }}>
            Про условия применения, можно узнать
            {' '}
            <LinkTo external={false} to="/promo">здесь.</LinkTo>
          </p>
          <CashbackSlider
            disableControlls={disableControlls}
            numberOfCashback={getMaxCashbackToUse(userCashback, totalPrice)}
            setSelectedUserCashback={setSelectedUserCashback}
          />
        </>
      )}

      <Backdrop
        classes={{ root: classes.backdropRoot }}
        open={splashScreen}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openPromotionAlert}
        onClose={onCloseSnackbar}
      >
        <Alert
          severity="error"
        >
          Не получилось загрузить скидки, попробуйте перезагрузить страницу.
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openCheckorderAlert}
        onClose={onCloseSnackbar}
      >
        <Alert
          severity="error"
        >
          {checkorderAlertText ? checkorderAlertText : "Не получилось определить стоимость доставки, попробуйте перезагрузить страницу."}
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={openStreetAlert}
        onClose={onCloseSnackbar}
      >
        <Alert
          severity="error"
        >
          Не получилось загрузить улицы, попробуйте перезагрузить страницу.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CartFields;
