import { CartReceiptTotalprice as CartReceiptTotalpriceClass, CartReceiptTotalpriceH3, CartReceiptTotalpriceH2 } from './cartReceiptTotalPrice.module.css';
import H2 from '../../../../Blocks/H2/H2';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

interface CartReceiptTotalPriceProps {
    totalPrice: number;
}

const CartReceiptTotalPrice = ({
  totalPrice,
}: CartReceiptTotalPriceProps) => (
  <ListItem disableGutters>
    <ListItemText primary="Общая стоимость:" />
    <ListItemSecondaryAction>
      <H2 className={CartReceiptTotalpriceH2}>{`${totalPrice} ₽`}</H2>
    </ListItemSecondaryAction>
  </ListItem>
);

export default CartReceiptTotalPrice;
