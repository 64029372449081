import strapiAPI from './strapiApi';
import { captureException } from '@sentry/gatsby';

const sendPromocode = (phone, cart, sum, date, coupon, isSelfService) => {
  const items = getItems(cart);

  const order = {
    order: {
      Items: items,
      Customer: {
        Phone: phone,
        Name: 'Alias',
      },
      isSelfService,
      Sum: sum,
      Date: date || null,
    },
    coupon,
  };

  return strapiAPI.post('/orders/getdiscounts', order);
};

const getItems = (cart) => cart.map((val) => (val.modifsSelected
  ? ({
    ItemName: val.Name,
    ItemCode: (val.OptionSelectedID && val.OptionSelectedID.trim())
    || (val.iikoID && val.iikoID.trim()),
    Amount: val.productQuantity,
    Price: val.Price * val.productQuantity,
    Modifiers: val.modifsSelected && val.modifsSelected.map((mod) => ({
      Name: mod.name,
      iikoCode: mod.iikoID.trim(),
      Amount: mod.amount,
    })),
  })
  : ({
    ItemName: val.Name,
    Price: val.Price * val.productQuantity,
    ItemCode: (val.OptionSelectedID && val.OptionSelectedID.trim())
    || (val.iikoID && val.iikoID.trim()),
    Amount: val.productQuantity,
  })));

export default sendPromocode;
