import {
  CartItemLeft as CartItemLeftClass, CartItemLeftH3, CartItemLeftStepper, CartItemLeftStepperMobile,
} from './cartItemLeft.module.css';
import CartItemLeftText from './CartItemLeftText/cartItemLeftText';
import { ADD_OR_INCREMENT_PRODUCT_TO_CART, REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID } from '../../../../Utils/Constants/constants';
import { Context } from '../../../../Utils/Store/store';
import { Product } from '../../../../types/types';
import H3 from '../../../Blocks/H3/H3';
import Stepper from '../../../Blocks/Stepper/stepper';
import React, { useContext } from 'react';
import Hidden from '@material-ui/core/Hidden';

interface CartItemLeftProps {
    product: Product;
}

const CartItemLeft = ({ product }: CartItemLeftProps) => {
  const { state, dispatch } = useContext(Context);
  const {
    Name, id, productQuantity, Weight, Price, iikoID, modifsSelected,
  } = product;

  const handlePlusClick = () => {
    dispatch({ type: ADD_OR_INCREMENT_PRODUCT_TO_CART, payload: product });
  };

  const handleMinusClick = () => {
    const prodid = product.OptionSelectedID ? product.OptionSelectedID : product.iikoID;

    dispatch({ type: REMOVE_OR_DECREMENT_PRODUCT_FROM_CART_BY_ID, payload: prodid });
  };

  const isProductHaveIikoID = !!(product.iikoID || product.OptionSelectedID);

  return (
    <div style={!isProductHaveIikoID ? { opacity: '.3' } : null} className={CartItemLeftClass}>
      <CartItemLeftText
        modifsSelected={modifsSelected}
        quantity={productQuantity}
        name={Name}
        weight={Weight}
      />
      <Stepper
        className={CartItemLeftStepper}
        onClickMinus={handleMinusClick}
        onClickPlus={handlePlusClick}
        label={productQuantity}
      />
      <Stepper
        className={CartItemLeftStepperMobile}
        onClickMinus={handleMinusClick}
        onClickPlus={handlePlusClick}
        label={productQuantity}
        size="sm"
      />
      <Hidden implementation="css" xsDown>
        <H3 className={CartItemLeftH3}>{`${Price * productQuantity} ₽`}</H3>
      </Hidden>
    </div>
  );
};

export default CartItemLeft;
