const getMaxCashbackToUse = (cashback, orderSum) => {
  const MAX_CASHBACK_PERCENT = 10;
  const flooredCashback = Math.floor(cashback);
  const maxCashbackForOrder = Math.floor((orderSum / 100) * MAX_CASHBACK_PERCENT);

  if (flooredCashback > maxCashbackForOrder) {
    return maxCashbackForOrder;
  }
  return flooredCashback;
};

export default getMaxCashbackToUse;
