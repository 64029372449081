import ProductCartToogleImage from './ProductCartToogleImage/productCartToogleImage';
import ProductCartToogleText from './ProductCartToogleText/productCartToogleText';
import { Context } from '../../../../../../Utils/Store/store';
import { Product } from '../../../../../../types/types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  groupRoot: {
    display: 'inline-flex',
  },
  dialogContentRoot: {
    scrollbarWidth: 'none',
    "-ms-overflow-style": 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  selected: {
    border: '3px solid var(--brand-green)',
    backgroundColor: '#ffffff',
  },
  root: {
    border: '3px solid transparent',
    backgroundColor: '#ffffff',
    alignItems: 'flex-start',
    marginRight: 24,
    width: '180px',
    '&$selected': {
      border: '3px solid var(--brand-green)',
      backgroundColor: '#ffffff',
      borderRadius: '3px',
      position: 'relative',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
    '&:hover': {
      boxShadow: 'rgb(0 0 0 / 17%) 0px 4px 13px',
      backgroundColor: '#ffffff',
    },
  },
  '@keyframes animatedgradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
});

const CartReceiptPromocodeModal = ({
  modalIsOpened, setModalIsOpened,
  promocodeName, setGiftProductSelected, arrayOfGifts,
}) => {
  const { state, dispatch } = useContext(Context);
  const [selectedGiftID, setSelectedGiftID] = React.useState(null);

  const classes = useStyles();

  const handleGiftSelection = (event, newAlignment) => {
    setSelectedGiftID(newAlignment);
  };

  const handleClose = () => {
    const selectedProd = arrayOfGifts[0].gifts.find((prd: Product) => prd.iikoID === selectedGiftID || prd.OptionSelectedID === selectedGiftID);

    setModalIsOpened(false);
    setGiftProductSelected({ ...selectedProd, Price: 0, productQuantity: 1 });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        disableEscapeKeyDown
        open={modalIsOpened}
        onClose={handleClose}
        aria-labelledby="окно-с-выбором-подарка"
      >
        <DialogTitle id="заголовок-в-окне-с-выбором-подарка">{`Выберите подарок по промокоду «${promocodeName}».`}</DialogTitle>
        <DialogContent classes={{root: classes.dialogContentRoot}} dividers>
          {arrayOfGifts.map((arrGfts) => (
            <ToggleButtonGroup
              value={selectedGiftID}
              exclusive
              key={arrGfts.name}
              classes={{ root: classes.groupRoot }}
              onChange={handleGiftSelection}
              aria-label="Выберите подарок"
            >
              {arrGfts.name}
              {arrGfts.gifts.map((gfts: Product) => (
                <ToggleButton
                  key={gfts.Name}
                  classes={{ selected: classes.selected, root: classes.root, hover: classes.hover }}
                  value={gfts.iikoID || gfts.OptionSelectedID}
                  aria-label={gfts.Name}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    width: '100%',
                    textAlign: 'left',
                  }}
                  >
                    <ProductCartToogleImage image={gfts.Image} alt={gfts.Name} />
                    <ProductCartToogleText
                      name={gfts.Name}
                      isSpicy={gfts.IsSpicy}
                      isVegan={gfts.IsVegan}
                    />
                  </div>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={!selectedGiftID} onClick={handleClose} color="primary">
            {selectedGiftID ? 'Выбираю этот!' : 'Нужно выбрать хоть что-то!'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CartReceiptPromocodeModal;
