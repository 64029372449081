import getDateRanges from '../../../../../../Utils/api/getDateRanges';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { captureException } from '@sentry/minimal';

interface CartReceiptDateTimeProps {
    time: String;
    timeRanges: [];
    handleTimeChange: () => void;
}

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectHeight: {
    maxHeight: '200px',
    width: '150px',
  },
}));

export const getWeekDay = (dayNum) => {
  switch (dayNum.toString()) {
    default:
    case '0':
      return 'Sunday';
    case '1':
      return 'Monday';
    case '2':
      return 'Tuesday';
    case '3':
      return 'Wednesday';
    case '4':
      return 'Thursday';
    case '5':
      return 'Friday';
    case '6':
      return 'Saturday';
  }
};

const CartReceiptDateTime = ({ time, handleTimeChange, customDate }: CartReceiptDateTimeProps) => {
  const classes = useStyles();
  const [ranges, setRanges] = useState(null);
  const currDate = new Date();
  const currDay = getWeekDay(currDate.getDay());

  useEffect(() => {
    if (!ranges) {
      (() => {
        const r = getDateRanges()
          .then((res) => setRanges(res.data.DataRangesList))
          .catch((err) => captureException(err));
      })();
    }
  }, []);

  const getRanges = customDate
    ? ranges && ranges[getWeekDay(customDate.day())]
    : ranges && ranges[currDay];

  // const sdf = () => {
  //   const prodWithTimeConstrains = state.cart.find((prod) => prod.categories && prod.categories.some((val) => val.TimeConstrain) || prod.categoryTimeConstrain);

  //   if (prodWithTimeConstrains) {
  //     const constrains = prodWithTimeConstrains.categories.TimeConstrain
  //       ? prodWithTimeConstrains.categories[0].TimeConstrain
  //       : prod.categoryTimeConstrain;

  //     if (constrains) {
  //       const fromConstrains = val.From.replace(/(:00)$/, '');
  //       const toConstrains = val.To.replace(/(:00)$/, '');

  //       const fRanges = val.From.replace(/(:00)$/, '');
  //       const tRanges = val.To.replace(/(:00)$/, '');

  //       const fRangesHours = timeRangeSelected.replace(/(:\d{2})/, '');
  //       const fRangesMinutes = timeRangeSelected.replace(/(\d{2}:)/, '');

  //       if

  //     }
  //   }
  // };

  return (
    <FormControl className={classes.formControl} variant="outlined" size="small">
      <InputLabel>Время</InputLabel>
      <Select
        inputMode="numeric"
        value={time}
        onChange={handleTimeChange}
        MenuProps={{ style: { height: ITEM_HEIGHT * 5.5, width: '20ch' } }}
      >
        {ranges && getRanges.map((val) => {
          const f = val.From.replace(/(:00)$/, '');
          const t = val.To.replace(/(:00)$/, '');

          return <MenuItem value={f}>{`${f}-${t}`}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default CartReceiptDateTime;
