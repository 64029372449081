import Layout from '../components/layout';
import SEO from '../components/seo';
import { Context } from '../Utils/Store/store';
import RecommendSection from '../components/RecommendSection/recommendSection';
import Cart from '../components/Cart/cart';
import React, { useContext, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { graphql, useStaticQuery } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    zIndex: 10000,
  },
});

const CartPage = ({ location }) => {
  const { state } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [showRecommendations, setShowRecommendations] = useState(true);

  const classes = useStyles();

  const data = useStaticQuery(graphql`{
  strapiRecommendationInCart {
    Name
    products {
      IsSpicy
      LongDescription
      Name
      Price
      Image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
      }
      SmallDescription
      Options {
        Name
        Price
        iikoID
        id
        Modificators {
          Name
          Price
          iikoModifID
        }
      }
      Status {
        Status
        id
      }
      GroupOfMods {
        id
        Mod {
          Name
          Price
          id
          iikoModifID
        }
      }
      Weight
      id
      iikoID
      isFixed
      mainingredients {
        Name
        id
      }
      subcategory {
        Name
        id
      }
    }
  }
}
`);

  return (
    <>
      <Backdrop classes={{ root: classes.root }} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Layout location={location} headerIsStatic>
        <SEO title="Корзина" />
        <Cart
          setIsLoading={setIsLoading}
          location={location}
          totalPrice={state.totalPrice}
          cart={state.cart}
          setShowRecommendations={setShowRecommendations}
        />
        {showRecommendations && (
        <RecommendSection
          heading={data.strapiRecommendationInCart.Name}
          products={data.strapiRecommendationInCart.products}
        />
        )}
      </Layout>

    </>
  );
};

export default CartPage;
