import { CartReceiptPersonaAmount as CartReceiptPersonaAmountClass, CartReceiptPersonaAmountH3 } from './cartReceiptPersonaAmount.module.css';
import Stepper from '../../../../Blocks/Stepper/stepper';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';

interface CartReceiptPersonaAmountProps {
    setPersonAmount: (arg: number) => void;
    personAmount: number;
}

const CartReceiptPersonaAmount = ({ setPersonAmount, personAmount }: CartReceiptPersonaAmountProps) => (
  <div>
    <ListItem disableGutters>
      <ListItemText primary="🍴 Приборы:" />
      <ListItemSecondaryAction>
        <Stepper onClickMinus={() => personAmount > 1 && setPersonAmount(personAmount - 1)} onClickPlus={() => personAmount < 50 && setPersonAmount(personAmount + 1)} size="sm" label={personAmount} />
      </ListItemSecondaryAction>
    </ListItem>
  </div>
);

export default CartReceiptPersonaAmount;
