import CartItemLeft from './CartItemLeft/cartItemLeft';
import CartItemImage from './CartItemLeft/CartItemImage/cartItemImage';
import { CartItem as CartItemClass, CartItemH3, CartItemPhotoText } from './cartItem.module.css';
import { Product } from '../../../types/types';
import H3 from '../../Blocks/H3/H3';
import React from 'react';

interface CartItemProps {
    product: Product;
}

const CartItem = ({ product }: CartItemProps) => (
  <div className={CartItemClass}>
    <div className={CartItemPhotoText}>

      <CartItemImage categories={product.categories} categoryTimeConstrain={product.categoryTimeConstrain} name={product.Name} filename={product.Image} />
      <CartItemLeft product={product} />
    </div>
    <H3 className={CartItemH3}>{`${product.Price * product.productQuantity} ₽`}</H3>
  </div>
);

export default CartItem;
