import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';

interface CartReceiptDeliveryPriceProps {
    deliveryCost: number;
}

const CartReceiptDeliveryPrice = ({ deliveryCost }:CartReceiptDeliveryPriceProps) => (
  <ListItem disableGutters>
    <ListItemText secondary={deliveryCost === 0 ? 'Бесплатная доставка — всегда приятно!' : null} primary="Стоимость доставки:" />
    <ListItemSecondaryAction>
      {`${deliveryCost} ₽`}
    </ListItemSecondaryAction>
  </ListItem>
);
export default CartReceiptDeliveryPrice;
