import { RecommendSectionRow as RecommendSectionRowClass } from './recommendSectionRow.module.css';
import { Product } from '../../../types/types';
import ProductCard from '../../ProductCard/productCard';
import React from 'react';

interface RecommendSectionRowProps {
    products: Product[];
}

const RecommendSectionRow = ({ products }: RecommendSectionRowProps) => (
  <div className={RecommendSectionRowClass}>
    {products.map((val, ind) => <ProductCard product={val} key={`${val.Name}-${val.iikoID}`} />)}
  </div>
);

export default RecommendSectionRow;
