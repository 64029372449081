import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// @ts-ignore
const HppIntegrationApi = typeof window !== 'undefined' && window.HppIntegrationApi !== undefined ? window.HppIntegrationApi : null;

const useStyles = makeStyles({
    groupRoot: {
        display: 'inline-flex',
    },
    dialogRoot: {
        zIndex: "300 !important"
    },
    dialogContentRoot: {
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'none',
        "-ms-overflow-style": 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },

});

const CartPayModal = ({
    open, amount
}) => {
    const classes = useStyles();
    const [payButtonNode, setPayButtonNode] = useState<HTMLButtonElement>();
    const [containerNode, setContainerNode] = useState<HTMLDivElement>();
    const [openModal, setOpenModal] = useState(true);

    const payContainerRef = useCallback(node => {
        if (node !== null) {
            setContainerNode(node);
        }
    }, []);

    const payButtonRef = useCallback(node => {
        if (node !== null) {
            setPayButtonNode(node);
        }
    }, []);

    useEffect(() => {
        if (document && document!.getElementById('initWidget')) {
            const orderData = {
                'order': {
                    "typeRid": "Sale Order Type_Single_CVV",
                    "amount": amount,
                    "title": "Заказ в Хочу Суши",
                    "currency": "RUB",
                    "description": "Чек на оплату",
                    "language": "ru",
                    "hppRedirectUrl": `https://wantsushi.ru/payed`,
                },
                'auth': {
                    userName: process.env.GATSBY_RNKB_LOGIN,
                    password: process.env.GATSBY_RNKB_PASSWORD
                },
                'terminalId': process.env.GATSBY_RNKB_TERMINAL,
                'merchantId': process.env.GATSBY_RNKB_MERCHANT
            }
            const hppIntegrationApi = new HppIntegrationApi(orderData, { onSuccess(data) { }, onFail(data) { } });
            const payButton = document.getElementById('continueButton');
            hppIntegrationApi.createWidget(document.getElementById('widgetContainer'), { payButton });
            payButton.click();

            setOpenModal(false)
        }
    }, [payButtonNode, containerNode])

    return (
        <Dialog
            style={{ zIndex: 300 }}
            fullWidth
            open={open && openModal}
            maxWidth="sm"
            disableAutoFocus
            disableRestoreFocus
            classes={{ root: classes.dialogRoot }}
            aria-labelledby="окно-с-оплатой"
        >
            <DialogTitle id="заголовок-в-окне-с-оплатой">Оплата</DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }} dividers>
                <div id="widgetContainer" ref={payContainerRef}></div>
                <button name="button" id="continueButton" aria-busy="false" ref={payButtonRef} variant="contained" color="primary" style={{ display: "none" }}>
                    <span></span>
                </button>
            </DialogContent>
            <DialogActions>
                <Button name="button" id="initWidget" aria-busy="false" variant="contained" color="primary">
                    Оплатить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CartPayModal;
