import RecommendSectionRow from './RecommendSectionRow/recommendSectionRow';
import { RecommendedSection as RecommendedSectionClass, RecommendedSectionH2 } from './recommendSection.module.css';
import { Product } from '../../types/types';

import H2 from '../Blocks/H2/H2';
import React from 'react';

interface RecommendSectionProps {
    products: Product[];
    heading: string;
}

const RecommendSection = ({ heading, products }: RecommendSectionProps) => {
  const prods = products.slice(0, 4);

  return (
    <div className={RecommendedSectionClass}>
      <H2 className={RecommendedSectionH2}>{heading}</H2>
      <RecommendSectionRow products={prods} />
    </div>
  );
};

export default RecommendSection;
