import CashIcon from './icons/cash.svg';
import TerminalIcon from './icons/terminal.svg';
import CardIcon from './icons/card.svg';
import React from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

const options = [{ name: 'Онлайн на сайте', icon: <CardIcon />, id: 1 }, { name: 'Картой при получении', icon: <TerminalIcon />, id: 2 }, { name: 'Наличными при получении', icon: <CashIcon />, id: 3 }];

const CartReceiptPaymentType = ({ setPaymentType }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(2);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getPaymentType = (index) => {
    switch (index) {
      default:
      case 0:
        return 'OnlineByCard';
      case 1:
        return 'ByCard';
      case 2:
        return 'Cash';
    }
  };

  const handleMenuItemClick = (event, index) => {
    setPaymentType(getPaymentType(index));
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ListItem disableGutters button onClick={handleClickListItem}>
        <ListItemText secondary={options[selectedIndex].name} primary="Способ оплаты" />
        <ListItemSecondaryAction>
          <Button size="small" onClick={handleClickListItem} color="primary">Изменить</Button>
        </ListItemSecondaryAction>
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

CartReceiptPaymentType.propTypes = {

};

export default CartReceiptPaymentType;
