import { CartItemLeftText as CartItemLeftTextClass, CartItemLeftTextH3, CartItemLeftTextPortion } from './cartItemLeftText.module.css';
import H3 from '../../../../Blocks/H3/H3';
import React from 'react';

interface CartItemLeftTextProps {
    name: string;
    weight: number;
    quantity: number;
}

const CartItemLeftText = ({
  name, weight, quantity, modifsSelected,
}: CartItemLeftTextProps) => {
  const portion = weight ? `${quantity} порц. / ${weight} гр.` : `${quantity} порц.`;

  return (
    <div className={CartItemLeftTextClass}>
      <H3 className={CartItemLeftTextH3}>{name}</H3>
      <span className={CartItemLeftTextPortion}>{portion}</span>
      {modifsSelected
      && modifsSelected.map((val) => <span key={`cartItemModifier-${val.name}-${val.iikoID}`} className={CartItemLeftTextPortion}>{`${val.name} ${val.amount} порц.`}</span>) }
    </div>
  );
};

export default CartItemLeftText;
