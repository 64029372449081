import { CartItemColContainer, CartItemColH2 } from './cartItemCol.module.css';
import CartIsEmpty from './CartIsEmpty/cartIsEmpty';
import { Product } from '../../../../types/types';
import { Context } from '../../../../Utils/Store/store';
import H2 from '../../../Blocks/H2/H2';
import CartItem from '../../CartItem/cartItem';
import React, { useContext } from 'react';

interface CartItemColProps {
    cart: Product[];
}

const CartItemCol = ({ cart }: CartItemColProps) => {
  const { state } = useContext(Context);
  // const isSomeProductTimeConstrained = cart.some((prod) => prod.categoryTimeConstrain);

  return (
    <div className={CartItemColContainer}>
      <H2 className={CartItemColH2}>Корзина</H2>
      {/* {isSomeProductTimeConstrained && (
        <Box mb="20px">
          <Alert
            color="success"
            severity="info"
          >
            Так как в корзине есть товары в предзаказ.
            Все товары можно заказать только к времени предзаказа товара.
          </Alert>
        </Box>
      )} */}
      {state.cart.length > 0
        ? cart.map((item) => <CartItem key={`cartitem-${item.Name}-${item.iikoID}`} product={item} />)
        : <CartIsEmpty />}

    </div>
  );
};

export default CartItemCol;
