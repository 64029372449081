import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './reactDateOverride.css';
import ruLocale from 'moment/locale/ru';
import moment from 'moment';

moment.updateLocale('ru', ruLocale);

export function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

export function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}

const DatePicker = ({ setDate, date }) => {
  const [focusedInput, setFocusedInput] = useState(false);


  return (
    <div className="App">
      <SingleDatePicker
        date={date}
        block
        withPortal
        disableScroll
        placeholder="Дата"
        displayFormat={() => 'DD/MM/YYYY'}
        phrases={{
          closeDatePicker: 'Закрыть',
          clearDate: 'Стереть',
          calendarLabel: 'Календарь',
          roleDescription: 'datepicker',
          focusStartDate: 'Откройте календарь, чтобы выбрать дату доставки',
          jumpToPrevMonth: 'Предыдущий месяц',
          jumpToNextMonth: 'Следующий месяц',
          keyboardShortcuts: 'Горячие клавиши',
          showKeyboardShortcutsPanel: 'Открыть панель с горячими клавишами',
          hideKeyboardShortcutsPanel: 'Закрыть панель с горячими клавишами.',
          openThisPanel: 'Открыть панель.',
          leftArrowRightArrow: 'Кнопки вправо и влево.',
          upArrowDownArrow: 'Кнопки вверх и вниз.',
          pageUpPageDown: 'Кнопки вверх и вниз.',
          homeEnd: 'Кнопки начало и конец',
          escape: 'Кнопка escape',
          questionMark: 'Знак вопроса',
          selectFocusedDate: 'Выберите дату.',
          moveFocusByOneDay: 'Двигаться вправо и влево по дням.',
          moveFocusByOneWeek: 'Двигаться вверх и вниз по дням.',
          moveFocusByOneMonth: 'Поменять месяц.',
          moveFocustoStartAndEndOfWeek: 'Перейти в начало или конец недели.',
          returnFocusToInput: 'Вернуться в поле выбора даты.',
          keyboardForwardNavigationInstructions: 'Перемещайте курсор вперед в календаре, чтобы выбрать дату. Нажмите на знак вопроса, чтобы узнать горячие клавиши.',
          keyboardBackwardNavigationInstructions: 'Перемещайте курсор назад в календаре, чтобы выбрать дату. Нажмите на знак вопроса, чтобы узнать горячие клавиши.',
        }}
        firstDayOfWeek={1}
        hideKeyboardShortcutsPanel
        startDateId="tata-start-date"
        onDateChange={(d) => setDate(d)}
        focused={focusedInput}
        numberOfMonths={1}
        isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())
          || isInclusivelyAfterDay(day, moment().add(1, 'weeks'))}
        onFocusChange={({ focused }) => setFocusedInput(focused)}
      />
    </div>
  );
};

export default DatePicker;
