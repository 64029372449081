import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface CartReceiptDeliveryPriceProps {
    cashback: string;
}

const useStyles = makeStyles(() => ({
  discountColor: {
    color: '#f50057',
  },
}));

const CartReceiptCashbackSelected = ({ cashback }:CartReceiptDeliveryPriceProps) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters>
      <ListItemText primary="Использован кешбек:" />
      <ListItemSecondaryAction className={classes.discountColor}>
        {`- ${cashback} ₽`}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
export default CartReceiptCashbackSelected;
