import CartReceiptDateTime from './CartReceiptDateTime/cartReceiptDateTime';
import DatePicker from '../../../../Blocks/DatePicker/datePicker';
import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const options = ['Как можно скорее', 'Предзаказ'];
const timeRanges = [
  { label: '11:30 - 12:00', format: { h: '11', m: '30' } },
  { label: '12:30 - 13:00', format: { h: '12', m: '30' } },
  { label: '13:30 - 14:00', format: { h: '13', m: '30' } },
  { label: '14:30 - 15:00', format: { h: '14', m: '30' } },
];

const CartReceiptDate = ({
  setCustomDate, customDate,
  setTimeRangeSelected, categoryTimeConstrain,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [time, setTime] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
    setTimeRangeSelected(event.target.value);
  };

  const handleMenuClick = (event, index) => {
    if (index === 0) {
      setCustomDate(null);
      setTime(null);
      setTimeRangeSelected(null);
    }
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ListItem disableGutters button onClick={handleClickListItem}>
        <ListItemText secondary={options[selectedIndex]} primary="Время доставки" />
        <ListItemSecondaryAction>
          <Button size="small" onClick={handleClickListItem} color="primary">Изменить</Button>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={selectedIndex === 1 || categoryTimeConstrain} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button>
            <div>
              {selectedIndex === 0
                ? 'Как можно скорее'
                : (
                  <div style={{ display: 'flex' }}>
                    <DatePicker date={customDate} setDate={setCustomDate} />
                    <Divider variant="middle" light orientation="vertical" flexItem />
                    <CartReceiptDateTime
                      time={time}
                      customDate={customDate}
                      timeRanges={timeRanges}
                      handleTimeChange={handleTimeChange}
                    />
                  </div>
                )}
            </div>
          </ListItem>
        </List>
      </Collapse>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

CartReceiptDate.propTypes = {

};

export default CartReceiptDate;
