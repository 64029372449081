import CartItemCol from './CartItemCol/cartItemCol';
import CartReceipt from './CartReceipt/cartReceipt';
import { CartGrid as CartGridClass } from './cartGrid.module.css';
import CartFields from './CartFields/cartFields';
import CartSuccess from './CartSuccess/cartSuccess';
import { Product } from '../../../types/types';
import { AuthContext, Context } from '../../../Utils/Store/store';
import strapiAPI from '../../../Utils/api/strapiApi';
import { REMOVE_ITEMS_FROM_CART } from '../../../Utils/Constants/constants';
import placeOrder from '../../../Utils/api/placeOrder';
import { isArrayEmpty } from '../../../Utils/utils';
import sendPromocode from '../../../Utils/api/sendPromocode';
import checkorder from '../../../Utils/api/checkorder';
import {
  ecommerceOnCartPage, ecommerceOnCheckoutPage, ecommerceOnPurchase, ecommerceOnSuccessPage,
} from '../../../Utils/ecommerce';
import { vkPixelInitiateCheckout, vkPixelPurchase } from '../../../Utils/vkPixel';
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import { captureException } from '@sentry/gatsby';
import Cookies from 'js-cookie';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import axiosRetry from 'axios-retry';

import CartPayModal from './CartPayModal/cartPayModal'
interface CartGridProps {
  cart: Product[];
  totalPrice: number;
  location: any;
  setIsLoading: (arg: boolean) => void;
}

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(/^(\+?7|8)9\d{9}$/, 'Проверьте номер, он должен начинаться с 8 или 7.')
    .required('Нужно обязательно указать номер телефона!'),
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, 'Имя не должно содержать цифры')
    .max(60, 'Имя должно быть не больше 60 символов')
    .required('Укажите, как к вам обращаться'),
  houseNumber: yup
    .string()
    .max(10, 'Номер дома должен быть не больше 10 символов')
    .required('Нужно указать номер дома'),
  apartment: yup
    .string()
    .max(10, 'Номер квартиры должен быть не больше 10 символов'),
  entrance: yup
    .string()
    .max(10, 'Номер подъезда должен быть не больше 10 символов'),
  housing: yup
    .string()
    .max(10, 'Корпус должен быть не больше 10 символов'),
  comment: yup
    .string()
    .max(500, 'Комментарий должен быть не больше 500 символов'),
  floor: yup
    .string()
    .max(10, 'Номер этажа должен быть не больше 10 символов'),
  street: yup
    .string()
    .required('Нужно указать улицу!'),
});

const CartGrid = ({
  cart, totalPrice, location, setIsLoading,
  setShowRecommendations,
}: CartGridProps) => {
  const { state, dispatch } = useContext(Context);
  const { authState } = useContext(AuthContext);

  // Meta
  const [orderIsSubmitted, setOrderIsSubmitted] = useState(false);
  const [placeOrderClicked, setPlaceOrder] = useState(false);
  const [disableControlls, setDisableControlls] = useState(false);

  // Alerts
  const [openOrderAlert, setOpenOrderAlert] = useState(false);
  const [openTimeoutAlert, setOpenTimeoutAlert] = useState(false);
  const [openZoneAlert, setOpenZoneAlert] = useState(false);

  // Cashback
  const [userCashback, setUserCashback] = useState(null);
  const [selectedUserCashback, setSelectedUserCashback] = useState(null);

  // Marketing
  const [promocodeNameUsed, setPromocodeNameUsed] = useState(null);
  const [discount, setDiscount] = useState([]);
  const [giftProductSelected, setGiftProductSelected] = useState(null);

  // Order Info
  const [personAmount, setPersonAmount] = useState(1);
  const [deliveryCost, setDeliveryCost] = useState(null);
  const [isSelfService, setSelfService] = useState(false);
  const [paymentType, setPaymentType] = useState('Cash');
  const [customDate, setCustomDate] = useState(null);
  const [timeRangeSelected, setTimeRangeSelected] = useState(null);
  const [infoFromApi, setInfoFromApi] = useState({
    Name: null,
    Phone: null,
    Email: null,
  });

  const [payModalOpened, setPayModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      phone: infoFromApi.Phone || '',
      name: infoFromApi.Name || '',
      email: infoFromApi.Email || '',
      houseNumber: '',
      apartment: '',
      entrance: '',
      housing: '',
      comment: '',
      floor: '',
      street: '',

    },
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: () => { },
  });

  const getFakeDataForTest = (phone) => ({
    values: {
      phone,
      name: 'Test',
      houseNumber: 1,
      street: 'Авдеева',
      apartment: 234,
    },
  });

  const getDiscountPrice = () => {
    let sum = 0;
    if (discount && Array.isArray(discount) && !isArrayEmpty(discount)) {
      discount.forEach((val) => {
        sum += val.discountSum;
      });
    } else {
      return null;
    }

    return sum;
  };

  const getPrice = () => {
    let sum = totalPrice;
    if (deliveryCost) {
      sum += deliveryCost;
    }
    if (discount && Array.isArray(discount) && !isArrayEmpty(discount)) {
      discount.forEach((val) => {
        sum -= val.discountSum;
      });
    }
    return sum;
  };

  const getPriceWithCashback = () => {
    let sum = totalPrice;
    if (selectedUserCashback) {
      sum -= selectedUserCashback;
    }
    if (deliveryCost) {
      sum += deliveryCost;
    }
    if (discount && Array.isArray(discount) && !isArrayEmpty(discount)) {
      discount.forEach((val) => {
        sum -= val.discountSum;
      });
    }
    return sum;
  };

  const checkIfIikoIsWorking = async () => {
    // Check if iikoCard is working
    try {
      await sendPromocode(infoFromApi.Phone,
        state.cart, state.totalPrice, null, promocodeNameUsed, true);
    } catch (error) {
      setIsLoading(false);
      captureException(error);
      setDisableControlls(false);
      setOpenOrderAlert(true);
      return false;
    }

    // Check if iiko is working
    try {
      const checkIiko = await checkorder(getFakeDataForTest(infoFromApi.Phone),
        authState.userId, state.cart, state.totalPrice, getUserCustomDate());
      if (checkIiko && checkIiko.data.data.resultState === 2) {
        setIsLoading(false);
        setDisableControlls(false);
        setOpenTimeoutAlert(true);
        return false;
      } if (checkIiko && checkIiko.data.data.resultState === 3) {
        setIsLoading(false);
        setDisableControlls(false);
        setOpenZoneAlert(true);
        return false;

      }
    } catch (error) {
      const errorCode = error.response && error.response.data.message
        ? error.response.data.message.resultState
        : null;

      if (errorCode && errorCode === 2) {
        setIsLoading(false);
        setDisableControlls(false);
        setOpenTimeoutAlert(true);
        return false;
      } if (errorCode && errorCode === 3) {
        setIsLoading(false);
        setDisableControlls(false);
        setOpenZoneAlert(true);
        return false;

      }
      setIsLoading(false);
      setDisableControlls(false);
      setOpenOrderAlert(true);
      return false;
    }
  }


  const getUserCustomDate = () => {
    if (customDate && timeRangeSelected) {
      const fHours = timeRangeSelected.replace(/(:\d{2})/, '');
      const fMinutes = timeRangeSelected.replace(/(\d{2}:)/, '');

      const d = customDate.set({
        hour: fHours,
        minute: fMinutes,
        second: 0,
      });

      return d.toISOString();
    }
    return null;
  };

  const payWithCard = async () => {
    setDisableControlls(true);

    const finalCart = [...state.cart];

    if (giftProductSelected) {
      finalCart.push(giftProductSelected);
    }

    const isIikoWorking = await checkIfIikoIsWorking();

    if (isIikoWorking !== false) {
      try {
        await placeOrder(formik, authState.userId,
          finalCart, personAmount,
          isSelfService, getPrice(),
          paymentType, getUserCustomDate(),
          promocodeNameUsed, getDiscountPrice(),
          selectedUserCashback);

      } catch (err) {
        captureException(err);
        setIsLoading(false);
        captureException(err);
        setOpenOrderAlert(true);
        return setDisableControlls(false);
      }
      setPayModalOpen(true);
    }

  }


  useEffect(() => {
    if (placeOrderClicked) {
      setIsLoading(true);
      (async () => {
        axiosRetry(strapiAPI, { retries: 100, retryDelay: () => 5000 });

        await strapiAPI.post('/users-permissions/getuserbyid', {
          customerID: authState.userId,
        }).then((res) => {
          const { data } = res;

          setUserCashback(data.walletBalances[0].balance);

          setInfoFromApi({
            Name: data.name || null,
            Phone: data.phone || null,
            Email: data.email || null,
          });

          return sendPromocode(data.phone,
            state.cart, state.totalPrice, null, null, isSelfService);
        }).then((res) => {
          const disks = [];

          res.data.forEach((val) => {
            if (!isArrayEmpty(val.discounts)) {
              const discSum = val.discounts.length > 1
                ? val.discounts.reduce((a, v) => a + v.discountSum, 0)
                : val.discounts[0].discountSum;

              disks.push({
                name: val.promotionName,
                discountSum: discSum,
              });
            }
          });
          setIsLoading(false);
          setDiscount(disks);
        }).catch(() => {
          setIsLoading(false);
        });
      })();
    }
  }, [placeOrderClicked]);

  useEffect(() => {
    const userId = Cookies.getJSON('user_id');

    if (location.search === '?order=checkout' && userId) {
      setIsLoading(true);
      ecommerceOnCheckoutPage(state.cart);

      (async () => {
        axiosRetry(strapiAPI, { retries: 100, retryDelay: () => 5000 });

        await strapiAPI.post('/users-permissions/getuserbyid', {
          customerID: userId,
        })
          .then((res) => {
            const { data } = res;

            setUserCashback(data.walletBalances[0].balance);

            setInfoFromApi({
              Name: data.name || null,
              Phone: data.phone || null,
              Email: data.email || null,
            });

            return sendPromocode(data.phone,
              state.cart, state.totalPrice, null, null, isSelfService);
          })
          .then((res) => {
            const disks = [];

            res.data.forEach((val) => {
              if (!isArrayEmpty(val.discounts)) {
                const discSum = val.discounts.length > 1
                  ? val.discounts.reduce((a, v) => a + v.discountSum, 0)
                  : val.discounts[0].discountSum;

                disks.push({
                  name: val.promotionName,
                  discountSum: discSum,
                });
              }
            });

            setIsLoading(false);
            setDiscount(disks);
          })
          .catch(() => {
            setIsLoading(false);
          });
      })();
    } else {
      ecommerceOnCartPage(state.cart);
    }
  }, []);

  const handleButtonClick = async () => {
    if (!placeOrderClicked) {
      setPlaceOrder(true);
      ecommerceOnCheckoutPage(state.cart);
      vkPixelInitiateCheckout(totalPrice);
      setShowRecommendations(false);
      return navigate('?order=checkout');
    }

    formik.validateForm()
      .then(async (err) => {
        if ((err && Object.keys(err).length === 0) || (isSelfService && !err.name && !err.phone)) {
          if (paymentType === 'OnlineByCard') {
            payWithCard();
          } else {
            setIsLoading(true);
            setDisableControlls(true);

            const finalCart = [...state.cart];

            if (giftProductSelected) {
              finalCart.push(giftProductSelected);
            }

            return placeOrder(formik, authState.userId,
              finalCart, personAmount,
              isSelfService, getPrice(),
              paymentType, getUserCustomDate(),
              promocodeNameUsed, getDiscountPrice(),
              selectedUserCashback)
              .then(() => {
                ecommerceOnPurchase(promocodeNameUsed, state.cart);
                ecommerceOnSuccessPage(state.cart);
                vkPixelPurchase(getPrice());

                setDisableControlls(false);
                setIsLoading(false);

                setOrderIsSubmitted(true);
                dispatch({ type: REMOVE_ITEMS_FROM_CART });
                return navigate('?order=success');
              })
              .catch((err) => {
                captureException(err);
                setIsLoading(false);
                setOpenOrderAlert(true);
                setDisableControlls(false);
              });
          }
        }
      });
  };

  const onCloseSnackbar = () => {
    setOpenOrderAlert(false);
  };

  useEffect(() => {
    if (location.search === '') {
      setPlaceOrder(false);
      setOrderIsSubmitted(false);
      setShowRecommendations(true);
    } else if (location.search === '?order=checkout') {
      setPlaceOrder(true);
      setShowRecommendations(false);
    }
  }, [location]);

  return (
    orderIsSubmitted
      ? <CartSuccess />
      : (
        <div className={CartGridClass}>
          {(placeOrderClicked && state.cart.length > 0) || (state.cart.length > 0 && authState.isAuthd && location.search === '?order=checkout')
            ? (
              <CartFields
                disableControlls={disableControlls}
                formik={formik}
                isSelfService={isSelfService}
                setSelfService={setSelfService}
                setDeliveryCost={setDeliveryCost}
                placeOrderClicked={placeOrderClicked}
                setDiscount={setDiscount}
                discount={discount}
                infoFromApi={infoFromApi}
                promocodeNameUsed={promocodeNameUsed}
                userCashback={userCashback}
                totalPrice={totalPrice}
                setSelectedUserCashback={setSelectedUserCashback}
              />
            )
            : <CartItemCol cart={cart} />}
          {state.cart.length > 0 && (
            <CartReceipt
              disableControlls={disableControlls}
              giftProductSelected={giftProductSelected}
              infoFromApi={infoFromApi}
              setTimeRangeSelected={setTimeRangeSelected}
              setCustomDate={setCustomDate}
              setGiftProductSelected={setGiftProductSelected}
              customDate={customDate}
              isSelfService={isSelfService}
              setPaymentType={setPaymentType}
              paymentType={paymentType}
              deliveryCost={deliveryCost}
              setPersonAmount={setPersonAmount}
              personAmount={personAmount}
              placeOrderClicked={location.search === '?order=checkout' || placeOrderClicked}
              setPlaceOrder={handleButtonClick}
              totalPrice={totalPrice}
              setDiscount={setDiscount}
              discount={discount}
              setPromocodeNameUsed={setPromocodeNameUsed}
              promocodeNameUsed={promocodeNameUsed}
              calculatedPrice={getPriceWithCashback()}
              setSelectedUserCashback={setSelectedUserCashback}
            />
          )}
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={openOrderAlert}
            onClose={onCloseSnackbar}
          >
            <Alert
              severity="error"
            >
              Не получилось оформить заказ, попробуйте еще раз.
            </Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={openTimeoutAlert}
            onClose={onCloseSnackbar}
          >
            <Alert
              severity="error"
            >
              Мы принимаем заказы с 10 утра, сделайте предзаказ или приходите позже :)
            </Alert>
          </Snackbar>
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={openZoneAlert}
            onClose={onCloseSnackbar}
          >
            <Alert
              severity="error"
            >
              Указанный адрес вне зоны доставки
            </Alert>
          </Snackbar>
          {payModalOpened && <CartPayModal amount={getPriceWithCashback().toString()} open={true} />}
        </div>
      )
  );
};

export default CartGrid;
