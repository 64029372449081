import {
  ProfileFieldsDeliveryContainer,
  ProfileFieldsDeliveryStreet, ProfileFieldsDeliveryAppartEntranceFloor,
  CartFieldsDeliveryNamePhone,
} from './cartFieldsDelivery.module.css';
import СartFieldsStreetField from '../CartFieldsStreetField/cartFieldsStreetField';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fields: {
    marginRight: '12px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '16px',
    },
  },
  nameField: {
    marginBottom: '12px',
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginRight: '16px',
    },
  },
  phoneField: {
    marginBottom: '12px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  }
}));

const CartFieldsDelivery = ({ formik, disableControlls, setOpenStreetAlert }) => {
  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit}>
      <p style={{ marginBottom: '30px', color: '#666', marginTop: '30px' }}>Заполните поля и узнайте стоимость доставки.</p>
      <div>
        <TextField
          id="name-deliveryForm"
          label="Ваше имя:"
          type="text"
          margin="none"
          fullWidth
          disabled={disableControlls}
          classes={{ root: classes.nameField }}
          required
          autoComplete="name"
          name="name"
          placeholder="Иван Иванов"
          variant="outlined"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.name)}
          helperText={formik.errors.name}
        />
      </div>
      <div className={ProfileFieldsDeliveryContainer}>
        <div className={ProfileFieldsDeliveryStreet}>
          <СartFieldsStreetField
            formik={formik}
            setOpenStreetAlert={setOpenStreetAlert}
            disableControlls={disableControlls}
          />
          <TextField
            name="houseNumber"
            id="house-number-deliveryForm"
            label="Номер дома:"
            required
            inputMode="numeric"
            type="text"
            margin="none"
            disabled={disableControlls}
            placeholder="12а"
            variant="outlined"
            value={formik.values.houseNumber}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.houseNumber)}
            helperText={formik.errors.houseNumber}
          />
        </div>
        <div className={ProfileFieldsDeliveryAppartEntranceFloor}>
          <TextField
            id="housing-deliveryForm"
            label="Корпус:"
            type="text"
            inputMode="numeric"
            disabled={disableControlls}
            margin="none"
            classes={{ root: classes.fields }}
            name="housing"
            placeholder="23"
            fullWidth
            variant="outlined"
            value={formik.values.housing}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.housing)}
            helperText={formik.errors.housing}
          />
          <TextField
            id="entrance-deliveryForm"
            label="Подъезд:"
            disabled={disableControlls}
            type="text"
            margin="none"
            inputMode="numeric"
            name="entrance"
            placeholder="Первый"
            fullWidth
            variant="outlined"
            value={formik.values.entrance}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.entrance)}
            helperText={formik.errors.entrance}
          />
        </div>
        <div className={ProfileFieldsDeliveryAppartEntranceFloor}>
          <TextField
            id="floor-deliveryForm"
            label="Этаж:"
            type="text"
            inputMode="numeric"
            disabled={disableControlls}
            margin="none"
            name="floor"
            placeholder="23"
            fullWidth
            classes={{ root: classes.fields }}
            variant="outlined"
            value={formik.values.floor}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.floor)}
            helperText={formik.errors.floor}
          />
          <TextField
            name="apartment"
            id="flat-deliveryForm"
            label="Квартира:"
            type="text"
            inputMode="numeric"
            disabled={disableControlls}
            margin="none"
            placeholder="32"
            fullWidth
            variant="outlined"
            value={formik.values.apartment}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.apartment)}
            helperText={formik.errors.apartment}
          />
        </div>
        <div>
          <TextField
            id="comment-deliveryForm"
            label="Комментарий:"
            type="text"
            margin="none"
            fullWidth
            name="comment"
            disabled={disableControlls}
            rows={4}
            placeholder="напр. пусть курьер не звонит, спит ребёнок, позвонить, как будет у подъезда, домофон не работает"
            variant="outlined"
            multiline
            value={formik.values.comment}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.comment)}
            helperText={formik.errors.comment}
          />

        </div>
      </div>
    </form>
  );
};

export default CartFieldsDelivery;
