import { ProductCartToogleTextH4 } from './productCartToogleText.module.css';
import React from 'react';

interface ProductCartToogleTextProps {
    name: string;
    isSpicy: boolean;
    isVegan: boolean;
}

const ProductCartToogleText = ({ name, isSpicy, isVegan }:ProductCartToogleTextProps) => (
  <h4 className={ProductCartToogleTextH4}>
    {name.trim()}
    {isSpicy && <span title="Остро!">🌶</span> }
    {isVegan && <span title="Для вегетарианцев">🌱</span> }
  </h4>
);

export default ProductCartToogleText;
