import { ProductCardToogleContainer } from './productCartToogleImage.module.css';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const ProductCartToogleImage = ({ image, alt }) => (
  <div className={ProductCardToogleContainer}>
    <GatsbyImage
      image={image.localFile.childImageSharp.gatsbyImageData}
      alt={alt}
      imgStyle={{ objectFit: 'contain' }}
    />
  </div>
);

export default ProductCartToogleImage;
