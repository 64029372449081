import CartGrid from './CartGrid/cartGrid';
import { Cart as CartClass, CartContainer } from './cart.module.css';
import { Product } from '../../types/types';
import React from 'react';

interface CartProps {
    cart: Product[];
    totalPrice: number;
    location: any;
    setIsLoading: (arg: boolean) => void;
}

const Cart = ({
  cart, totalPrice, location, setIsLoading,
  setShowRecommendations,
}: CartProps) => (
  <div className={CartClass}>
    <div className={CartContainer}>
      <CartGrid
        setShowRecommendations={setShowRecommendations}
        setIsLoading={setIsLoading}
        location={location}
        cart={cart}
        totalPrice={totalPrice}
      />
    </div>
  </div>
);

export default Cart;
