import CartReceiptPersonaAmount from './CartReceiptPersonaAmount/cartReceiptPersonaAmount';
import CartReceiptTotalPrice from './CartReceiptTotalPrice/cartReceiptTotalPrice';
import CartReceiptPromocode from './CartReceiptPromocode/cartReceiptPromocode';
import { CartReceipt as CartReceiptClass, CartReceiptButton, CartReceiptPromocodeContainer } from './cartReceipt.module.css';
import CartReceiptPaymentType from './CartReceiptPaymentType/cartReceiptPaymentType';
import CartReceiptDate from './CartReceiptDate/cartReceiptDate';
import CartReceiptDeliveryPrice from './CartReceiptDeliveryPrice/cartReceiptDeliveryPrice';
import CartReceiptCashbackSelected from './CartReceiptCashbackSelected/cartReceiptCashbackSelected';
import ButtonProduct from '../../../Blocks/ButtonProduct/buttonProduct';
import { AuthContext, Context } from '../../../../Utils/Store/store';
import AuthModal from '../../../AuthModal/authModal';
import { isArrayEmpty } from '../../../../Utils/utils';
import React, { useContext, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

interface CartReceiptProps {
    totalPrice: number;
    disableControlls: boolean;
    deliveryCost: string;
    setPlaceOrder: () => void;
    placeOrderClicked: boolean;
    setPersonAmount: (arg: number) => void;
    personAmount: number;
    customDate: Date;
    setPaymentType: () => void;
    setCustomDate: () => void;
    setTimeRangeSelected: () => void;
    infoFromApi: {};
    selectedUserCashback: number;
    calculatedPrice: number;
}

const CartReceipt = ({
  totalPrice, setPlaceOrder, placeOrderClicked,
  setPersonAmount, personAmount, deliveryCost, setPaymentType,
  paymentType, setCustomDate, customDate, setTimeRangeSelected,
  infoFromApi, giftProductSelected, setGiftProductSelected,
  isSelfService, setPromocodeNameUsed,
  setDiscount, discount, categoryTimeConstrain, disableControlls,
  selectedUserCashback,
  calculatedPrice,
}: CartReceiptProps) => {
  const { authState } = useContext(AuthContext);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const { state } = useContext(Context);

  const handleButtonClick = (e) => {
    e.stopPropagation();

    // If user not authd open auth modal
    if (!authState.isAuthd) {
      return setOpenAuthModal(true);
    }

    // If authd open delivery modal
    setPlaceOrder();
  };

  const onAuthModalClose = () => {
    setOpenAuthModal(false);
  };

  const getButtonLabel = () => {
    let label;

    if (authState.isAuthd) {
      if (placeOrderClicked && paymentType === 'OnlineByCard') {
        label = 'Оплатить заказ';
      } else if (placeOrderClicked && (paymentType === 'Cash' || paymentType === 'ByCard')) {
        label = 'Оформить заказ';
      } else {
        label = 'Перейти к оформлению';
      }
    } else {
      label = 'Войти на сайт';
    }

    return label;
  };

  const prodsAmount = state.cart
    .map((prod) => prod.productQuantity)
    .reduce((acc, val) => acc + val, 0);

  return (
    <div className={CartReceiptClass}>

      <ListItem disableGutters>
        <ButtonProduct
          onClick={handleButtonClick}
          label={getButtonLabel()}
          disableElevation={!placeOrderClicked}
          className={CartReceiptButton}
          isDisabled={disableControlls}
        />
      </ListItem>
      {!authState.isAuthd && (
      <ListItem disableGutters>
        <ListItemText secondary="Авторизуйтесь, чтобы мы могли приготовить вам заказ и начислить кешбэк." />
      </ListItem>
      )}
      <CartReceiptTotalPrice
        totalPrice={calculatedPrice}
      />
      <ListItem disableGutters>
        <ListItemText primary={`Товары (${prodsAmount}):`} />
        <ListItemSecondaryAction>
          {`${totalPrice} ₽`}
        </ListItemSecondaryAction>
      </ListItem>
      {selectedUserCashback && <CartReceiptCashbackSelected cashback={selectedUserCashback} />}
      {typeof deliveryCost === 'number' && !isSelfService && <CartReceiptDeliveryPrice deliveryCost={deliveryCost} />}
      {discount && Array.isArray(discount)
       && !isArrayEmpty(discount)
      && (discount.map((val) => (
        <ListItem disableGutters>
          <ListItemText primary={`Скидка «${val.name}»:`} />
          <ListItemSecondaryAction>
            {`- ${val.discountSum} ₽`}
          </ListItemSecondaryAction>
        </ListItem>
      )))}
      {giftProductSelected && (
      <ListItem disableGutters>
        <ListItemText primary="Подарок:" />
        <ListItemSecondaryAction>
          {giftProductSelected.Name}
        </ListItemSecondaryAction>
      </ListItem>
      )}
      {!placeOrderClicked && (
      <CartReceiptPersonaAmount
        setPersonAmount={setPersonAmount}
        personAmount={personAmount}
      />
      )}
      {placeOrderClicked
      && <CartReceiptPaymentType setPaymentType={setPaymentType} />}
      {placeOrderClicked && (
      <CartReceiptDate
        categoryTimeConstrain={categoryTimeConstrain}
        setTimeRangeSelected={setTimeRangeSelected}
        customDate={customDate}
        setCustomDate={setCustomDate}
      />
      )}
      {placeOrderClicked
            && (
            <div className={CartReceiptPromocodeContainer}>
              <CartReceiptPromocode
                setDiscount={setDiscount}
                discount={discount}
                isSelfService={isSelfService}
                infoFromApi={infoFromApi}
                setPromocodeNameUsed={setPromocodeNameUsed}
                setGiftProductSelected={setGiftProductSelected}
              />
            </div>
            )}

      <AuthModal isOpen={openAuthModal} onClose={onAuthModalClose} />
    </div>
  );
};

export default CartReceipt;
