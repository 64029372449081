import strapiAPI from './strapiApi';

const checkorder = (formik, userId, cart, sum, date) => {
  const items = getItems(cart);

  const order = {
    order: {
      Date: date || null,
      Items: items,
      Customer: {
        UserID: userId,
        Phone: formik.values.phone,
        Name: formik.values.name,
      },
      Delivery: {
        City: 'Севастополь',
        Home: formik.values.houseNumber,
        Street: formik.values.street,
        Floor: formik.values.floor,
        Apartment: formik.values.apartment,
        Entrance: formik.values.entrance,
        Comment: formik.values.comment,
        Housing: formik.values.housing,
      },
      Sum: sum,
    },
  };
  return strapiAPI.post('/orders/checkorder', order);
};

const getItems = (cart) => cart.map((val) => (val.modifsSelected
  ? ({
    ItemName: val.Name,
    ItemCode: (val.OptionSelectedID && val.OptionSelectedID.trim())
    || (val.iikoID && val.iikoID.trim()),
    Amount: val.productQuantity,
    Price: val.Price,
    Modifiers: val.modifsSelected && val.modifsSelected.map((mod) => ({
      Name: mod.name,
      iikoCode: mod.iikoID.trim(),
      Amount: mod.amount,
    })),
  })
  : ({
    ItemName: val.Name,
    Price: val.Price,
    ItemCode: (val.OptionSelectedID && val.OptionSelectedID.trim())
    || (val.iikoID && val.iikoID.trim()),
    Amount: val.productQuantity,
  })));

export default checkorder;
