import axiosRetry from 'axios-retry';
import axios from 'axios';

const placeOrder = (formik, userId, cart, personAmount,
  isSelfService, sum, paymentType, date, coupon, discountSum,
  cashbackUsed) => {
  let order;
  const items = getItems(cart);

  axiosRetry(axios, { retries: 100, retryDelay: () => 5000 });

  if (isSelfService) {
    order = {
      order: {
        Items: items,
        Customer: {
          UserID: userId,
          Phone: formik.values.phone,
          Name: formik.values.name,
        },
        isSelfService: true,
        CashbackUsed: cashbackUsed,
        PersonCount: personAmount,
        Sum: sum,
        DiscountSum: discountSum,
        Coupon: coupon || null,
        Comment: itemsForComment(items),
        PaymentType: paymentType,
        Date: date || null,
      },
    };
  } else {
    order = {
      order: {
        Items: items,
        Customer: {
          UserID: userId,
          Phone: formik.values.phone,
          Name: formik.values.name,
        },
        Coupon: coupon || null,
        CashbackUsed: cashbackUsed,
        Delivery: {
          City: 'Севастополь',
          Home: formik.values.houseNumber,
          Street: formik.values.street,
          Floor: formik.values.floor,
          Apartment: formik.values.apartment,
          Housing: formik.values.housing,
          Entrance: formik.values.entrance,
          Comment: formik.values.comment,
        },
        PersonCount: personAmount,
        Sum: sum,
        DiscountSum: discountSum,
        Date: date || null,
        Comment: itemsForComment(items),
        PaymentType: paymentType,
      },
    };
  }
  return axios.post(`${process.env.GATSBY_STRAPI_URL}/orders/placeorder`, order);
};

const itemsForComment = (arr) => arr.map((val) => (val.Modifiers ? `${val.ItemName} x${val.Amount} (${val.Modifiers.map((a) => `${a.Name} x${a.Amount}`).join(', ')})` : `${val.ItemName} x${val.Amount}`)).join(', ');

const getItems = (cart) => cart.map((val) => (val.modifsSelected
  ? ({
    ItemName: val.Name,
    ItemCode: (val.OptionSelectedID && val.OptionSelectedID.trim())
      || (val.iikoID && val.iikoID.trim()),
    Amount: val.productQuantity,
    Price: val.Price,
    Modifiers: val.modifsSelected && val.modifsSelected.map((mod) => ({
      Name: mod.name,
      iikoCode: mod.iikoID.trim(),
      Amount: mod.amount,
    })),
  })
  : ({
    ItemName: val.Name,
    Price: val.Price,
    ItemCode: (val.OptionSelectedID && val.OptionSelectedID.trim())
      || (val.iikoID && val.iikoID.trim()),
    Amount: val.productQuantity,
  })));

export default placeOrder;
