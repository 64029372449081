import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface SliderProps {
    numberOfCashback: number,
    disableControlls: boolean,
    setSelectedUserCashback: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  buttonColor: {
    color: '#EE7238',
  },
  buttonColorMain: {
    backgroundColor: '#EE7238',
    '&hover': {
      backgroundColor: '#d66531',
    },
  },
}));

const CashbackSlider = ({
  numberOfCashback,
  setSelectedUserCashback, disableControlls,
}: SliderProps) => {
  const [value, setValue] = useState(0);
  const [amountSelected, setAmountSelected] = useState(false);
  const classes = useStyles();

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const getButtonLabel = () => `Снять ${value} ₽`;

  const handleApplyButtonClick = () => {
    if (value > 0 && value <= numberOfCashback) {
      setSelectedUserCashback(value);
      setAmountSelected(true);
    }
  };
  const handleCancelButtonClick = () => {
    setAmountSelected(false);
    setSelectedUserCashback(null);
  };

  const marks = [
    {
      value: 0,
      label: '0 ₽',
    },
    {
      value: numberOfCashback,
      label: `${numberOfCashback} ₽`,
    },
  ];

  const hasValue = value > 0;

  return (
    <Box className={classes.container}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs>
          <Slider
            valueLabelDisplay="on"
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            valueLabelDisplay="on"
            aria-labelledby="input-slider"
            marks={marks}
            disabled={amountSelected || disableControlls}
            max={numberOfCashback}
            valueLabelFormat={(number) => `${number} ₽`}
          />
        </Grid>
      </Grid>
      {(hasValue && !amountSelected) && (
      <Button
        className={classes.buttonColorMain}
        onClick={handleApplyButtonClick}
        disableElevation
        color="primary"
        variant="contained"
        disabled={value < 1 || amountSelected || disableControlls}
      >
        {getButtonLabel()}
      </Button>
      )}
      {amountSelected && (
      <Button
        disabled={disableControlls}
        onClick={handleCancelButtonClick}
        color="secondary"
        variant="text"
      >
        Не снимать кешбэк
      </Button>
      )}
    </Box>
  );
};

export default CashbackSlider;
