import { CartReceiptPromocode as CartReceiptPromocodeStyle } from './cartReceiptPromocode.module.css';
import CartReceiptPromocodeModal from './CartReceiptPromocodeModal/cartReceiptPromocodeModal';
import { AuthContext, Context } from '../../../../../Utils/Store/store';
import sendPromocode from '../../../../../Utils/api/sendPromocode';
import { isArrayEmpty } from '../../../../../Utils/utils';
import { Product } from '../../../../../types/types';
import strapiAPI from '../../../../../Utils/api/strapiApi';
import React, { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import { captureException } from '@sentry/minimal';
import CircularProgress from '@material-ui/core/CircularProgress';

const NO_SUCH_PROMOCODE_ERROR = 'Нет промокода с таким названием';
const NO_VALUE_ADDED_ERROR = 'Нужно указать название промокода';
const SUBMIT_ERROR_AGAIN = 'Повторите еще раз, что-то не так!';

interface CartReceiptPromocodeProps {
  setGiftProductSelected: () => void;
  setPromocodeNameUsed: () => void;
  infoFromApi: {
    Phone: string,
  };
}

const CartReceiptPromocode = ({
  setPromocodeNameUsed,
  setGiftProductSelected, infoFromApi,
  isSelfService, setDiscount, discount,
}: CartReceiptPromocodeProps) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [promoValue, setPromoValue] = useState('');
  const [promoSubmitted, setPromoSubmitted] = useState(false);
  const [arrayOfGifts, setArrayOfGifts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch } = useContext(Context);
  const { authState } = useContext(AuthContext);

  const handleBtnClick = async () => {
    if (promoValue && promoValue.length > 0) {
      return strapiAPI.get('/promocodes')
        .then((res) => {
          const promoInStrapi = res.data && res.data
            .find((val) => val.Alias.trim().toUpperCase() === promoValue.toUpperCase().trim());

          if (promoInStrapi) {
            setIsLoading(true);
            sendPromocode(infoFromApi.Phone,
              state.cart, state.totalPrice, null, promoInStrapi.IikoPromocode, isSelfService)
              .then((res) => {
                const discs = [];

                res.data.forEach((val) => {
                  if (!isArrayEmpty(val.discounts)) {
                    const discSum = val.discounts.length > 1
                      ? val.discounts.reduce((a, v) => a + v.discountSum, 0)
                      : val.discounts[0].discountSum;
                    discs.push({
                      name: val.promotionName,
                      discountSum: discSum,
                    });
                  }

                  if (!isArrayEmpty(val.freeProducts)) {
                    const gifts = [];

                    val.freeProducts.forEach((free) => {
                      free.productCodes.forEach((code) => {
                        // TODO: find prods with option id
                        const giftProd = state.products
                          .find((prod: Product) => code === prod.iikoID);

                        if (giftProd) {
                          gifts.push(giftProd);
                        } else {
                          const maybeOption: Product = state.products
                            .find((prod: Product) => prod.Options && prod.Options
                              .some((opt) => opt.iikoID === code));

                          if (maybeOption) {
                            gifts.push({ ...maybeOption, OptionSelectedID: code, Name: `${maybeOption.Name} | ${maybeOption.Options.find((opt) => opt.iikoID === code).Name}` });
                          }
                        }
                      });
                    });
                    setArrayOfGifts([...arrayOfGifts, { name: val.promotionName, gifts }]);
                    setModalIsOpened(true);
                  }
                });
                setDiscount(discs);
                setShowError(false);
                setPromocodeNameUsed(promoInStrapi.IikoPromocode);
                setPromoSubmitted(true);
                setIsLoading(false);
              })
              .catch((err) => {
                captureException(err);
                setPromoSubmitted(false);
                setPromocodeNameUsed(null);
                setIsLoading(false);
                setShowError(true);
                setErrorText(SUBMIT_ERROR_AGAIN);
              });
          } else {
            setErrorText(NO_SUCH_PROMOCODE_ERROR);
            setShowError(true);
          }
        });
    }
    setErrorText(NO_VALUE_ADDED_ERROR);
    setShowError(true);
  };

  return (
    <div className={CartReceiptPromocodeStyle}>
      <ListItem disableGutters>
        <TextField
          id="promocode-cartReceipt"
          onChange={(e) => setPromoValue(e.target.value)}
          label="Промокод:"
          type="text"
          size="small"
          error={showError}
          fullWidth
          disabled={promoSubmitted}
          placeholder="Промокод"
          variant="outlined"
          helperText={showError && errorText}
        />
      </ListItem>
      <Button
        disabled={promoSubmitted || isLoading || promoValue.length == 0}
        color="primary"
        variant='contained'
        disableElevation
        onClick={handleBtnClick}
      >
        {isLoading ? <CircularProgress color="primary" size={20} /> : 'Применить промокод'}
      </Button>
      <CartReceiptPromocodeModal
        modalIsOpened={modalIsOpened}
        setGiftProductSelected={setGiftProductSelected}
        setModalIsOpened={setModalIsOpened}
        arrayOfGifts={arrayOfGifts}
        promocodeName={promoValue}
      />
    </div>

  );
};

// const getFreeProducts = (obj) => obj.data.data.loyatyResult.programResults.map((val) => ({ name: val.name, freeProds: [...val.freeProducts.map((valu) => getProdsFromCodes(valu.productCodes, prods))] }));
const getPromotions = (loyatyResult) => programResults.map((val) => ({ name: val.name, freeProds: [...val.freeProducts.map((valu) => getProdsFromCodes(valu.productCodes, prods))] }));

const getProdsFromCodes = (codes, prods) => codes.reduce((acc, cur) => {
  const a = prods.find((pr) => pr.code === cur);
  if (a) {
    acc.push(a);
  }
  return acc;
}, []);

export default CartReceiptPromocode;
