import CartIcon from './icons/cartIsEmpty.svg';
import { CartIsEmpty as CartIsEmptyClass, CartIsEmptyH3 } from './cartIsEmpty.module.css';
import Paragraph from '../../../../Blocks/Paragraph/paragraph';
import React from 'react';

const CartIsEmpty = (props) => (
  <div>
    <Paragraph className={CartIsEmptyH3}>Пока в корзине нечего нет</Paragraph>
  </div>
);

export default CartIsEmpty;
