import { CartItemLeftImage as CartItemLeftImageClass } from './cartItemImage.module.css';
import { CategoriesType, CategoryTimeConstrainType } from '../../../../../types/types';
import { isArrayEmpty } from '../../../../../Utils/utils';
import { getCategoriesWithTimeConstrains } from '../../../../ProductCard/productCard';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

interface CartItemLeftImageProps {
    filename: string;
    name: string;
    categoryTimeConstrain: CategoryTimeConstrainType;
    categories: CategoriesType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 1,
  },
}));

const CartItemLeftImage = ({
  name, filename,
  categoryTimeConstrain, categories,
}: CartItemLeftImageProps) => {
  const classes = useStyles();
  const itemsWithTimeConstrains = getCategoriesWithTimeConstrains(categories);

  const getTimeCostrainsChips = () => {
    if (!isArrayEmpty(itemsWithTimeConstrains)) {
      return (
        <Chip
          classes={{ root: classes.root }}
          label={`C ${itemsWithTimeConstrains[0].TimeConstrain.From.replace(/(:00)$/g, '')} до ${itemsWithTimeConstrains[0].TimeConstrain.To.replace(/(:00)$/g, '')}`}
        />
      );
    } if (categoryTimeConstrain) {
      return (
        <Chip
          classes={{ root: classes.root }}
          label={`C ${categoryTimeConstrain.From.replace(/(:00)$/g, '')} до ${categoryTimeConstrain.To.replace(/(:00)$/g, '')}`}
        />
      );
    }
  };

  return (
    <div>
      {getTimeCostrainsChips()}
      <GatsbyImage
        image={filename.localFile.childImageSharp.gatsbyImageData}
        alt={name}
        className={CartItemLeftImageClass}
        imgStyle={{ objectFit: 'contain' }}
      />
    </div>
  );
};

export default CartItemLeftImage;
